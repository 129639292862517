import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {OrderType} from "../../../Apis/Models/IOrderDto";


interface OrderTypeWithLabel {
    value: string | undefined;
    label: string;
}
export const useOrderType = (): {orderTypes: OrderTypeWithLabel[], getOrderTypeLabel: (orderType: OrderType) => string} => {

    const { t } = useTranslation();
    
    const orderTypes = useMemo(() => [
        { value: undefined, label: t('all')},
        { value: 'Default', label: t('orderType.default')},
        { value: 'Web', label: t('orderType.Web')},
        { value: 'Settlement', label: t('orderType.Settlement')},
    ], [t]);
    
    const getOrderTypeLabel = (orderType: OrderType): string => {
        return orderTypes.find(x => x.value === orderType)?.label ?? '';
    }
    
    return { orderTypes, getOrderTypeLabel };
    
}