import styles from "./ShowOrderLines.module.scss";
import InventoryIcon from "../../../Assets/SVG/InventoryIcon";
import { useTranslation } from "react-i18next";
import {IOrderDto} from "Apis/Models/IOrderDto";
import OrderLineGroup from "./OrderLineGroup";

interface IProps {
    order: IOrderDto;
}
const ShowOrderLines = (props: IProps) => {

    const { t } = useTranslation();

    const { order } = props;

    return (
        <div>
            <div className={styles.sectionTitle}>
                <InventoryIcon fill={"#6D4D4D"} />
                {t('orderLines')}
            </div>
            <OrderLineGroup lines={order.orderLines} />
        </div>
    )

}

export default ShowOrderLines
