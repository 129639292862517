import styles from "Pages/Orders/Show.module.scss";
import OrderLineDetails from "./OrderLineDetails";
import {IOrderLineDto} from "Apis/Models/IOrderDto";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import {formatNumber} from "../../../Utils/NumberUtils";
import {getOrderLinesTotal, getOrderLinesTotalInclVat, getOrderLinesTotalVat} from "../Utils/OrderLineUtils";

interface IProps {
    lines: IOrderLineDto[];
}
const OrderLineGroup = ({lines}: IProps) => {

    const { t } = useTranslation();

    return (
        <div>
            {lines.map(x => <OrderLineDetails key={x.id} orderLine={x} />)}

            <FlexRow justify={"end"}>
                <div className={styles.total}>
                    <div>
                        {t('total')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotal(lines))} DKK
                    </div>
                    <div>
                        {t('vat')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotalVat(lines))} DKK
                    </div>
                    <div>
                        {t('totalInclVat')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotalInclVat(lines))} DKK
                    </div>
                </div>
            </FlexRow>
        </div>
    )

}

export default OrderLineGroup
