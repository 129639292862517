import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {Trans, useTranslation} from "react-i18next";
import useToasts from "../../../Hooks/UseToasts";
import React, {useState} from "react";
import {IProductDto} from "../../../Apis/Models/IProductDto";
import {useBulkUpdateProducts} from "../../../Apis/ProductApi";
import {useForm} from "react-hook-form";
import ControlledInput from "../../../Components/Input";
import {IFileInformationDto} from "../../../Apis/Models/IFileDto";
import styles from "./ProductForm.module.scss";
import FileUpload from "../../../Components/FileUpload/FileUpload";
import Loader from "../../../Assets/SVG/Loader";
import XIcon from "../../../Assets/SVG/XIcon";

type FormData = {
    find?: string | undefined;
    replace?: string | undefined;
    designUnitCostPrice?: number | undefined;
    designUnitSalesPrice?: number | undefined;
    inventoryItemUnitCostPrice?: number | undefined;
    inventoryItemUnitSalesPrice?: number | undefined;
}

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess?: () => void;
    products: IProductDto[];
}

const ProductSearchAndReplace = (props: Props) => {

    const {t} = useTranslation();

    const {showSuccessToast} = useToasts();

    const {control, handleSubmit, reset} = useForm<FormData>();
    const [productImages, setProductImages] = useState<IFileInformationDto[]>([]);

    const addProductImage = (file: IFileInformationDto) => {
        setProductImages(current => [...current, file]);
    }

    const removeProductImage = (index: number) => {
        setProductImages(current => {
            let copy = [...current]
            copy.splice(index, 1)
            return copy;
        });
    }

    const {mutateAsync, isLoading} = useBulkUpdateProducts();

    const bulkUpdate = async (data: FormData) => {
        await mutateAsync({
            productIds: props.products.map(x => x.id),
            productImages: productImages,
            ...data
        });

        showSuccessToast(t('bulkUpdatedProducts'));

        reset({});

        props.toggleVisible();
        props.onSuccess && props.onSuccess();
    }

    return (
        <Modal title={t('searchAndReplace')} visible={props.visible} onCancel={() => props.toggleVisible()}
               modalStyle={{overflowY: 'visible'}}>
            <Space size={"large"}>
                <Trans i18nKey={`youAreAboutToReplaceTheNameAndPriceForXProducts`}
                       values={{count: props.products.length}}/>

                <form onSubmit={handleSubmit(bulkUpdate)}>
                    <Space size={"large"}>
                        <ControlledInput name={"find"} control={control} placeholder={t('find')}/>
                        <ControlledInput name={"replace"} control={control} placeholder={t('replace')}/>

                        <hr/>

                        <ControlledInput name={"designUnitCostPrice"} control={control}
                                         placeholder={t('designUnitCostPrice')} type={"number"}/>
                        <ControlledInput name={"designUnitSalesPrice"} control={control}
                                         placeholder={t('designUnitSalesPrice')} type={"number"}/>

                        <hr/>

                        <ControlledInput name={"inventoryItemUnitCostPrice"} control={control}
                                         placeholder={t('inventoryItemUnitCostPrice')} type={"number"}/>
                        <ControlledInput name={"inventoryItemUnitSalesPrice"} control={control}
                                         placeholder={t('inventoryItemUnitSalesPrice')} type={"number"}/>

                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(5, 150px)', gap: 15}}>
                            <FileUpload callback={addProductImage} accept={['.jpeg', '.jpg', '.png']}
                                        render={(loading, isDragging) => (
                                            <div
                                                className={`${styles.mockupFileContainer} ${isDragging && styles.dragging}`}>
                                                {loading ? (<Loader color={"red"} height={50} width={50}/>) : (<>
                                                    <>
                                                        {t('upload')}
                                                    </>
                                                </>)}
                                            </div>
                                        )}/>

                            {productImages?.map((image, index) => (
                                <div className={styles.relative} key={image.fileGuid}>
                                    <div className={styles.delete} onClick={() => removeProductImage(index)}>
                                        <XIcon/>
                                    </div>
                                    <img height={150} width={150} src={image.url} alt={image.fileName}/>
                                </div>
                            ))}
                        </div>

                        <FlexRow justify={"end"}>
                            <Button type={"secondary"} buttonType={"submit"} loading={isLoading}
                                    text={t('bulkUpdate')}/>
                        </FlexRow>
                    </Space>
                </form>
            </Space>
        </Modal>
    )
}

export default ProductSearchAndReplace
